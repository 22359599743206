<template>
  <div class="has-header">
    <a-collapse accordion v-model:activeKey="activeKey">
      <a-collapse-panel key="1" header="1 计划定义">
        <div class="form-title">计划名称<span class="req">*</span>:</div>
        <a-input v-model="addParams.name" />
      </a-collapse-panel>
      <a-collapse-panel key="2" header="2 目标用户">
        <div>
          数据来源：
          <a-radio-group v-model="dataSourceValue" default-value="group">
            <a-radio value="group">智能分群列表</a-radio>
          </a-radio-group>
        </div>
        <div class="source-select-list">
          <a-row>
            <a-col :span="2" class="title">智能分群列表:</a-col>
            <a-col :span="6">
              <a-select
                class="selectInput"
                v-model:value="groupSelect"
                @change="handleFnSearch"
              >
                <a-select-option
                  v-for="listItem in groupListOptions"
                  :key="listItem.id"
                  :value="listItem.id"
                >
                  {{ listItem.name }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
        </div>
        <search-form :items="items" @fnSearch="handleFnSearch" />
        <grid
          ref="gridRef"
          bordered
          size="small"
          :columns="columns"
          :url="url"
          :search="search"
          :btn-operation="btnOperation"
        >
          <template #operation="{ record }">
            <operation :options="options" :record="record" />
          </template>
        </grid>
      </a-collapse-panel>
      <a-collapse-panel key="3" header="3 发送渠道-消息内容">
        <div class="form-data-content">
          <!--发送渠道-消息内容-->
          <ul class="message-type-list">
            <li class="message-type-item">
              <a class="message-type-item-a btn-icon-a-2 btn-icon-a-2-orange-2">
                <MailOutlined />
              </a>
              <div class="message-type-item-text">EDM邮件</div>
            </li>
          </ul>

          <ul class="message-content-list">
            <!--邮件 start-->
            <li class="message-content-item">
              <a-row type="flex">
                <a-col :flex="1">
                  <div class="select-content">
                    <div class="select-content-title">请选择EDM邮件</div>
                    <ul class="select-list">
                      <li
                        :class="[
                          'select-item',
                          {
                            'select-item-selected': addParams.msgId === item.id,
                          },
                        ]"
                        v-for="(item, index) in mailTempList"
                        :key="index"
                        @click="getMessageItem(item)"
                      >
                        {{ item.name }}
                      </li>
                    </ul>
                  </div>
                </a-col>
                <a-col :flex="4">
                  <div class="preview-content">
                    <div class="mac-wrapper">
                      <div class="mac-bar">
                        <ul class="mac-list">
                          <li class="mac-item"></li>
                          <li class="mac-item"></li>
                          <li class="mac-item"></li>
                        </ul>
                      </div>
                      <iframe
                        class="mac-content"
                        :src="mailTplUrl"
                        frameborder="0"
                      ></iframe>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </li>
            <!--邮件 end-->
          </ul>
        </div>
      </a-collapse-panel>
      <a-collapse-panel key="4" header="4 发送时间">
        <div class="send-time-wrapper">
          <a-row type="flex">
            <a-col flex="200px">
              <div class="send-time-wrapper-left">
                <div
                  class="send-time-type-wrapper"
                  @click="addParams.sendType = '1'"
                >
                  <a
                    :class="[
                      'send-time-type-a',
                      'btn-icon-a',
                      { 'bg-red': addParams.sendType === '1' },
                    ]"
                  >
                    <UploadOutlined />
                  </a>
                  <div class="send-time-type-text">立即发送</div>
                </div>
                <div class="send-time-type-wrapper">
                  <a
                    :class="[
                      'send-time-type-a',
                      'btn-icon-a',
                      { 'bg-red': false },
                    ]"
                  >
                    <ClockCircleOutlined />
                  </a>
                  <div class="send-time-type-text">定时发送</div>
                  <div class="send-time-type-text">(开发中……)</div>
                </div>
              </div>
            </a-col>
            <a-col flex="auto">
              <div class="send-time-wrapper-right">
                <div
                  class="immediately-wrapper"
                  v-if="addParams.sendType === '1'"
                >
                  <div class="immediately-content">
                    <div class="wave-wrapper">
                      <div class="wave wave-1"></div>
                      <div class="wave wave-2"></div>
                      <div class="wave wave-3"></div>
                      <a class="send-time-type-a btn-icon-a bg-red">
                        <UploadOutlined />
                      </a>
                    </div>
                    <div class="immediately-send-tip-1">
                      您已选择立即发送方式
                    </div>
                    <div class="immediately-send-tip-2">
                      我们将在您完成消息配置时，立即发送消息到目标用户
                    </div>
                  </div>
                </div>
                <div class="timing-wrapper" v-else>
                  <table class="data-table" style="width: 40%">
                    <tr>
                      <td class="desc">
                        发送时间<span class="required-flag">*</span>
                      </td>
                      <td>
                        <!--<el-date-picker-->
                        <!--v-model="addParams.jobTime"-->
                        <!--type="datetime"-->
                        <!--class="Wdate"-->
                        <!--value-format="yyyy-MM-dd HH:mm:ss"-->
                        <!--format="yyyy-MM-dd HH:mm"-->
                        <!--placeholder=""-->
                        <!--&gt;-->
                        <!--</el-date-picker>-->
                        <!--<a-date-picker-->
                        <!--format="YYYY-MM-DD HH:mm:ss"-->
                        <!--:disabled-date="disabledDate"-->
                        <!--:disabled-time="disabledDateTime"-->
                        <!--:show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"-->
                        <!--/>-->
                      </td>
                    </tr>
                    <tr></tr>
                  </table>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-collapse-panel>
      <a-collapse-panel key="5" header="5 发送确认">
        <div class="send-confirm-wrapper-left">
          <table class="data-table">
            <tr>
              <td class="desc">消息名称</td>
              <td>{{ addParams.name }}</td>
            </tr>
            <tr>
              <td class="desc">目标用户</td>
              <td>
                {{ addParams.pushPlanMemberList.length }}人<span
                  style="color: #aaa"
                  >()</span
                >
              </td>
            </tr>
            <tr>
              <td class="desc">发送渠道</td>
              <td>EDM邮件</td>
            </tr>
            <tr>
              <td class="desc">发送内容</td>
              <td>
                <div class="message-all-wrapper" style="width: 100%">
                  <!--邮件 start-->
                  <div class="mac-wrapper">
                    <div class="mac-bar">
                      <ul class="mac-list">
                        <li class="mac-item"></li>
                        <li class="mac-item"></li>
                        <li class="mac-item"></li>
                      </ul>
                    </div>
                    <iframe
                      class="mac-content"
                      :src="mailTplUrl"
                      frameborder="0"
                    ></iframe>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="desc">发送时间</td>
              <td>
                <span>立即发送</span>
                <span :hidden="true">定时发送，2020-02-04 10:20</span>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <a-button class="abutton" type="primary" @click="send('2')"
                  >保存并发送</a-button
                >
                <a-button class="abutton" @click="send('1')">存草稿</a-button>
                <a-button class="abutton" @click="$router.push('/auto/push')"
                  >取消</a-button
                >
              </td>
            </tr>
          </table>
        </div>
      </a-collapse-panel>
    </a-collapse>
    <!--<div class="scroll-content">-->
    <!--<div class="content-wrapper">-->
    <!--&lt;!&ndash;5、发送确认 start&ndash;&gt;-->
    <!--<div :class="['form-data', { 'form-data-selected': step === 5 }]">-->
    <!--<div-->
    <!--@click="step = step === 5 ? 0 : 5"-->
    <!--:class="['form-data-content-title', { 'bg-red': step === 5 }]"-->
    <!--&gt;-->
    <!--<div class="form-data-content-title-item">5 发送确认</div>-->
    <!--<div class="form-data-content-title-item">-->
    <!--<i class="fas fa-angle-down"></i>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="form-data-content">-->
    <!--&lt;!&ndash;发送确认&ndash;&gt;-->
    <!--<div class="send-confirm-wrapper">-->
    <!--&lt;!&ndash;发送预览 start&ndash;&gt;-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--&lt;!&ndash;5、发送确认 end&ndash;&gt;-->
    <!--</div>-->
    <!--</div>-->
  </div>
</template>

<script>
import { Collapse, Input, Radio, Select, Col, Row } from 'ant-design-vue';
import SearchForm from '@/components/searchForm';
import marketingApi from '@/api/marketing.js';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import {
  MailOutlined,
  UploadOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons-vue';

export default {
  components: {
    ACollapse: Collapse,
    ACollapsePanel: Collapse.Panel,
    AInput: Input,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    SearchForm,
    Grid,
    Operation,
    ASelect: Select,
    ASelectOption: Select.Option,
    ACol: Col,
    ARow: Row,
    MailOutlined,
    UploadOutlined,
    ClockCircleOutlined,
  },
  data() {
    return {
      items: [
        { key: 'name', label: '姓名' },
        { key: 'email', label: '邮箱' },
      ],
      columns: [
        { key: 'name', label: '姓名' },
        { key: 'email', label: '邮箱' },
        { key: 'phone', label: '电话' },
      ],
      url: '',
      btnOperation: [],
      search: {},
      options: [
        {
          type: 'view',
          label: '详情',
          icon: 'EyeTwoTone',
          permission: 'portraitApi:view',
          fnClick: ({ id }) =>
            this.$router.push(`/vipcenter/portraitApi/details/${id}`),
        },
      ],
      step: 1,
      tableData: [],
      loading: false,
      containerLoading: false,
      queryGroupParams: {
        name: '',
        email: '',
      },
      addParams: {
        msgId: '', // 消息模板ID
        groupId: '', // 群组ID
        name: '', // 计划名称
        sendChannel: 'email', // 发送渠道
        pushPlanMemberList: [], // 目标集合
        sendType: '1', // 发送类型(1立即 2定时)
        jobTime: '', // 定时发送时间
        status: '1', // 计划状态(1草稿 2启用)
      },
      mailTplUrl: '',
      groupListOptions: [],
      groupSelect: '',
      mailTempList: [],
      dataSourceValue: 'group',
      activeKey: ['1'],
    };
  },
  props: {
    id: String,
  },
  computed: {
    title() {
      return this.id ? '修改推送计划' : '新增推送计划';
    },
  },
  beforeRouteEnter(to, from, next) {
    const { id } = to.params;
    to.meta.title = id ? '修改推送计划' : '新增推送计划';
    next();
  },
  watch: {},
  mounted() {
    this.getGroupList();
    this.getMailTempList();
    this.id && this.getEditDefaultData();
  },
  beforeRouteLeave(to, from, next) {
    // this.$destroy();
    next();
  },
  methods: {
    // 修改获取默认值
    async getEditDefaultData() {
      const { id } = this;
      this.containerLoading = true;
      //  查询推送计划详情
      const detailRes = await marketingApi.getPushPlanDetail(id);
      // 查询推送目标集合
      const targetsRes = await marketingApi.getPushPlanTargets({ pushId: id });
      this.containerLoading = false;
      if (!targetsRes)
        return console.error(
          `查询不到id为 ${id} 的推送目标集合，请检查参数是否正确`
        );
      if (!detailRes)
        return console.error(
          `查询不到id为 ${id} 的推送计划详情，请检查参数是否正确`
        );
      this.addParams = {
        ...detailRes.data,
        ...{ pushPlanMemberList: targetsRes.data },
      };
      Reflect.deleteProperty(this.addParams, 'sendStatus');
      this.getList();

      const { msgId } = this.mailTempList.find(
        item => item.id === this.addParams.msgId
      );
      this.getMessageItem({ msgId, isInit: true });
    },

    // 处理table默认选中
    toggleTableSelection(pushPlanMemberList) {
      pushPlanMemberList.forEach(item => {
        this.tableData.map(row => {
          if (row.ismartek_id !== item.ismartekId) return;
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        });
      });
    },

    // 获取群组列表
    async getGroupList() {
      const res = await marketingApi.getGroupList();
      this.groupListOptions = res;
    },

    // 获取邮件模板列表
    async getMailTempList() {
      const res = await marketingApi.getMailTempList();
      this.mailTempList = res;
    },

    // 获取邮件模板url
    async getMessageItem({ id, msgId }) {
      if (id) this.addParams.msgId = id;
      const res = await marketingApi.getMessageItem('', msgId);
      this.mailTplUrl = res.url;
    },

    // 获取群组成员列表
    async getList() {
      this.loading = true;

      let params = this.queryGroupParams;
      params.groupId = this.addParams.groupId;
      params.size = this.size;
      const res = await marketingApi.getGroupItem(params);
      this.loading = false;
      if (!res) return;
      this.tableData = res.data.records;
      this.size = parseInt(res.data.size);
      this.total = parseInt(res.data.total);

      // 处理默认选中
      this.toggleTableSelection(this.addParams.pushPlanMemberList);
    },

    resetForm() {
      this.queryGroupParams = {
        name: '',
        email: '',
      };
      this.getList();
    },

    // 表格多选变化
    handleSelectionChange(val) {
      this.addParams.pushPlanMemberList = val.map(item => {
        return {
          ismartekId: item.ismartek_id,
          name: item.name,
          target: item.email,
        };
      });
    },

    async send(type) {
      this.addParams.status = type;
      this.containerLoading = true;
      const res = await marketingApi.saveOrUpdate(this.addParams);
      let sendStauts = true;
      // 保存并发送 单独调用一次发送接口
      if (type === '2') {
        const sendRes = await marketingApi.sendPushPlan({ id: res.data.id });
        if (!sendRes) sendStauts = false;
      }

      this.containerLoading = false;
      if (!res || !sendStauts) return;

      this.$message({
        message: '操作成功！',
        type: 'success',
      });
      this.$router.replace({ name: 'pushPlan' });
    },
    handleFnSearch(value) {
      this.url = marketingApi.listPage + '?groupId=' + this.groupSelect;
      this.search = value;
    },
  },
};
</script>
<style lang="less" scoped>
// @import './sendData/sendData.scss';
/deep/ .ant-collapse-item-active .ant-collapse-header {
  background: #5979f8;
  color: #fff;
}

.req {
  color: #f00;
}

.has-header {
  padding: 16px;
}

.form-title {
  display: inline-block;
  padding: 16px;
}

input {
  width: 30%;
}

.source-select-list {
  padding: 10px 0;

  .title {
    text-align: right;
  }

  .selectInput {
    width: 97%;
  }
}

.message-type-list {
  text-align: center;

  .message-type-item-a {
    width: 80px;
    height: 80px;
    background: #f7a655;
    color: #fff;
    display: inline-block;
    border-radius: 40px;
    text-align: center;
    line-height: 80px;
    font-size: 28px;
  }
}

/**
    *message-content-list start
     */
.select-list {
  max-height: 500px;
  overflow: auto;
  width: 100%;

  .select-item {
    padding: 10px 16px;
    color: #666;
    cursor: pointer;
  }

  .select-item:nth-child(2n + 1) {
    background-color: #fafbfd;
  }

  .select-item-selected {
    color: #fff;
    background-color: #1e95f3 !important;
  }
}

/**
    *message-content-list end
     */
/**
*mac-wrapper start
 */
.mac-wrapper {
  //margin: 0px auto;
  //width: 300px;
  height: 500px;
  box-shadow: 0px 0px 10px #aaa;
}

.mac-bar {
  position: relative;
  width: 100%;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #535353;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.mac-list {
  position: absolute;
  top: 0px;
  left: 15px;
  width: auto;
  height: 100%;
}

.mac-item {
  position: relative;
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: #f4a62a;
}

.mac-item:first-child {
  background-color: #e74c3c;
}

.mac-item:last-child {
  background-color: #16a085;
}

.mac-content {
  width: 100%;
  height: calc(100% - 36px);
  overflow: auto;
}

.email-content {
  transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
}

/**
    *mac-wrapper end
     */
.send-time-wrapper {
  text-align: center;

  .send-time-wrapper-left {
    border-right: 1px solid #eee;

    .send-time-type-wrapper {
      padding: 20px 0;
      vertical-align: middle;
    }
  }

  .send-time-wrapper-right {
    .immediately-content {
      padding-top: 88px;
    }
  }
}

.send-time-type-a {
  display: inline-block;
  width: 80px;
  height: 80px;
  text-align: left;
  border: 1px solid #eee;
  border-radius: 100%;
  line-height: 80px;
  font-size: 32px;
  text-align: center;
  color: #000;
}

.bg-red {
  color: #fff !important;
  background-color: #fb576e;
  background: linear-gradient(90deg, #f83d8c 30%, #ff8639);
}

.send-confirm-wrapper-left {
  table.data-table {
    width: 100%;

    .desc {
      text-align: right;
      padding-right: 30px;
      line-height: 40px;
    }
  }
}

.abutton {
  margin: 20px 10px;
}
</style>
